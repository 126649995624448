export default [
  {
    path: '/user/change-password',
    name: 'user-change-password',
    component: () => import('@/views/user/ChangePassword.vue'),
    meta: {
      // pageTitle: 'Account Settings',
      resource: 'ACL',
      action: 'read',
    },
  },
]
