export default [
  {
    path: '/documents/process-rut',
    name: 'documents-process',
    component: () => import('@/views/documents/ProcessRUT.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/documents/list-jobs',
    name: 'documents-list-jobs',
    component: () => import('@/views/documents/ListJobs.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
]
