export default [
  {
    path: '/coming-soon',
    name: 'error-coming-soon',
    component: () => import('@/views/error/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/not-authorized', // /pages/miscellaneous/not-authorized is too long for an error
    name: 'error-not-authorized',
    component: () => import('@/views/error/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      // resource: 'Auth',
    },
  },
  {
    path: '/under-maintenance', // Same as before
    name: 'error-under-maintenance',
    component: () => import('@/views/error/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/error', // Same as before
    name: 'error-misc',
    component: () => import('@/views/error/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
]
