export default [
  {
    path: '/lawyer/list-jobs',
    name: 'lawyer-list-jobs',
    component: () => import('@/views/lawyer/ListJobs.vue'),
    meta: {
      resource: 'lawyer',
      action: 'read',
    },
  },
  {
    path: '/lawyer/job/:id',
    name: 'lawyer-job',
    // props: true,
    component: () => import('@/views/lawyer/Job.vue'),
    meta: {
      resource: 'lawyer',
      action: 'read',
    },
  },
  // {
  //   path: '/lawyer/requests',
  //   name: 'lawyer-requests',
  //   component: () => import('@/views/lawyer/RequestsPerUser.vue'),
  //   meta: {
  //     resource: 'lawyer',
  //     action: 'read',
  //   },
  // },
]
