export default {
  // Endpoints
  loginEndpoint: '/jwt2/login',
  userInfoEndpoint: '/user/info',
  registerEndpoint: '/jwt2/register',
  refreshEndpoint: '/jwt2/refresh-token',
  logoutEndpoint: '/jwt2/logout',
  fetchTicketDataEndpoint: '/jwt2/activate',
  activateEndpoint: '/jwt2/activate',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
