export default [
  {
    path: '/users/create',
    name: 'users-create',
    component: () => import('@/views/users/Create.vue'),
  },
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/users/List.vue'),
  },
  {
    path: '/users/relate',
    name: 'users-relate',
    component: () => import('@/views/users/Relate.vue'),
  },
]
