export default [
  {
    path: '/supervision/overview',
    name: 'supervision-overview',
    component: () => import('@/views/supervision/Overview.vue'),
    meta: {
      resource: 'oversee',
      action: 'read',
    },
  },
  {
    path: '/supervision/analysis', // /:filterBy?
    name: 'supervision-analysis',
    props: true,
    component: () => import('@/views/supervision/AnalysisPerUser.vue'),
    meta: {
      resource: 'oversee',
      action: 'read',
    },
  },
  {
    path: '/supervision/requests',
    name: 'supervision-requests',
    component: () => import('@/views/supervision/RequestsPerUser.vue'),
    meta: {
      resource: 'oversee',
      action: 'read',
    },
  },
]
